import service from '@/utils/request';

// @Tags RmGroupMapping
// @Summary 创建RmGroupMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmGroupMapping true "创建RmGroupMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmGroupMapping/createRmGroupMapping [post]
export var createRmGroupMapping = function createRmGroupMapping(data) {
  return service({
    url: "/rmGroupMapping/createRmGroupMapping",
    method: 'post',
    data: data
  });
};

// @Tags RmGroupMapping
// @Summary 删除RmGroupMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmGroupMapping true "删除RmGroupMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmGroupMapping/deleteRmGroupMapping [delete]
export var deleteRmGroupMapping = function deleteRmGroupMapping(data) {
  return service({
    url: "/rmGroupMapping/deleteRmGroupMapping",
    method: 'delete',
    data: data
  });
};

// @Tags RmGroupMapping
// @Summary 删除RmGroupMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmGroupMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmGroupMapping/deleteRmGroupMapping [delete]
export var deleteRmGroupMappingByIds = function deleteRmGroupMappingByIds(data) {
  return service({
    url: "/rmGroupMapping/deleteRmGroupMappingByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmGroupMapping
// @Summary 更新RmGroupMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmGroupMapping true "更新RmGroupMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmGroupMapping/updateRmGroupMapping [put]
export var updateRmGroupMapping = function updateRmGroupMapping(data) {
  return service({
    url: "/rmGroupMapping/updateRmGroupMapping",
    method: 'put',
    data: data
  });
};

// @Tags RmGroupMapping
// @Summary 用id查询RmGroupMapping
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmGroupMapping true "用id查询RmGroupMapping"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmGroupMapping/findRmGroupMapping [get]
export var findRmGroupMapping = function findRmGroupMapping(params) {
  return service({
    url: "/rmGroupMapping/findRmGroupMapping",
    method: 'get',
    params: params
  });
};

// @Tags RmGroupMapping
// @Summary 分页获取RmGroupMapping列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmGroupMapping列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmGroupMapping/getRmGroupMappingList [get]
export var getRmGroupMappingList = function getRmGroupMappingList(params) {
  return service({
    url: "/rmGroupMapping/getRmGroupMappingList",
    method: 'get',
    params: params
  });
};