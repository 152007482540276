import { render, staticRenderFns } from "./rm_group_mapping.vue?vue&type=template&id=3a808e2b"
import script from "./rm_group_mapping.vue?vue&type=script&lang=js"
export * from "./rm_group_mapping.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/gva_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a808e2b')) {
      api.createRecord('3a808e2b', component.options)
    } else {
      api.reload('3a808e2b', component.options)
    }
    module.hot.accept("./rm_group_mapping.vue?vue&type=template&id=3a808e2b", function () {
      api.rerender('3a808e2b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/rm_group_mapping.vue"
export default component.exports